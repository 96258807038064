<template>
  <modal name="send-message-modal" @closed="closeSendMail" :scrollable="true" :width="1150" :height="820"
         :maxHeight="600">
    <div class="clearfix">
      <h4 class="float-left">{{ $t('labels.send-email') }}</h4>
      <button type="submit" v-if="prepareSending" class="btn btn-success float-right mt-3 mr-3"
              @click="prepareSending = false">{{ $t('labels.back') }}
      </button>
      <button type="submit" v-if="!prepareSending" class="btn btn-success float-right mt-3 mr-3"
              @click="sendMailEstimate">{{ $t('labels.next') }} &raquo;
      </button>
    </div>
    <div class="container bt-1">
      <div class="row bb-1" v-show="prepareSending">
        <div class="col-7 br-1 pt-3">
          <div v-html="subject" class="fw-600 mb-2"></div>
          <div v-html="body" class="mail-preview"></div>
        </div>
        <div class="col-5 pt-3">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <h6 class="sublabel">{{ $t('labels.estimated') }}</h6>
                <div class="fw-600">{{ prepareSending }} {{ $t('labels.receivers') }}</div>
              </div>
            </div>
          </div>
          <div class="row bt-1 pt-3 pb-3" v-if="prepareSending">
            <div class="col">
              <div class="form-check">
                <input class="form-check-input" v-model="delayedSending" type="checkbox" value="" id="defaultCheck1"/>
                <label class="form-check-label" for="defaultCheck1">
                  {{ $t('labels.delay-mail-delivery') }}
                </label>
              </div>
            </div>
          </div>
          <div class="row bt-1 pt-3 pb-3" v-if="delayedSending">
            <div class="col-6">
              <div class="form-group">
                <label for="subject">{{ $t('labels.date-of-dispatch') }}</label>
                <input type="date" v-model="date" class="form-control" id="subject" aria-describedby="emailHelp"
                       placeholder=""/>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label for="subject">{{ $t('labels.time') }}</label>
                <input type="time" v-model="time" class="form-control" id="subject" aria-describedby="emailHelp"
                       placeholder=""/>
              </div>
            </div>
          </div>
          <div class="row bt-1 pt-3 pb-3" v-if="prepareSending">
            <div class="col-12">
              <button type="submit" class="btn btn-success float-right mt-2" @click="sendMail">
                {{ $t('labels.send') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-show="!prepareSending">
        <div class="col-8 pt-3">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="subject">{{ $t('labels.subject') }}</label>
                <input type="text" v-model="currentSubject" class="form-control" id="subject" aria-describedby="emailHelp"
                       placeholder=""/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="subject">{{ $t('labels.signature') }}</label>

                <select v-model="signature" class="form-control">
                  <option v-for="signature in signatures" :value="signature.id" :key="signature.id">{{
                      signature.name
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <ckeditor :editor="editor" v-model="currentBody" :config="editorConfig"></ckeditor>
          </div>
          <div class="form-group">
            <VueFileAgent
                ref="vueFileAgent"
                :theme="'list'"
                :multiple="true"
                :deletable="true"
                :meta="true"
                :maxSize="'10MB'"
                :maxFiles="5"
                :helpText="this.$t('labels.attach-one-or-many-files')"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDelete($event)"
                @delete="fileDeleted($event)"
                v-model="fileRecords"
            ></VueFileAgent>
          </div>
        </div>
        <div class="col-4 pt-3 bl-1">
          <div class="form-group">
            <label for="subject">{{ $t('labels.receiver') }}</label>

            <div class="scrollable-list-email-list">
              <div class="row bb-1 pt-3 pb-2" v-for="list in lists.data" :key="list.id">
                <div class="col">
                  <div class="pl-3">
                    <input v-model="recipients" class="form-check-input" type="checkbox" :value="list.id"
                           :id="'list' + list.id"/>
                    <label class="form-check-label" :for="'list' + list.id">
                      {{ list.label }}
                      <span
                          class="text-muted small"
                          style="margin-left: 3px; position: relative; top: -1px; background-color: #f7f7f7; border-radius: 4px; padding: 2px 4px;"
                      >{{ list.label_model_type }}</span
                      >
                    </label></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  props: {
    body: String,
    subject: String,
  },

  data() {
    return {
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue
      currentSubject: '',
      currentBody: '',
      editor: ClassicEditor,
      editorConfig: {},
      signatures: [],
      lists: [],
      recipients: [],
      prepareSending: false,
      delayedSending: false,
      date: '',
      time: '',
      signature: '',
      files: [],
      formData: new FormData()
    };
  },
  created() {
    this.currentBody = this.body;
    this.currentSubject = this.subject;
  },
  mounted() {
    this.$modal.show('send-message-modal');

    // Get available signatures
    BojAPI.getEmailSignatures().then((response) => {
      this.signatures = response.data;
    });

    // Get available lists
    BojAPI.getEmailLists().then((response) => {
      this.lists = response.data;
    });
  },
  methods: {


    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },

    /**
     * Handle file upload
     */
    uploadFiles: function () {

      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
      this.fileRecordsForUpload = [];
    },

    /**
     * Handle file selection
     */
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },

    /**
     * Remove files from queue.
     */
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },


    /**
     * Close modal
     */
    closeSendMail() {
      this.$emit('closed');
    },

    /**
     * Handle upload of attachments
     */
    handleAttachmentUpload(event) {
      this.files = this.$refs.files.files;
    },

    /**
     * Sends a preview of current mail
     *
     * TODO: Currently not used.
     */
    sendEmailPreview() {
      if (this.currentSubject.length === 0) {
        alert(this.$t('labels.subject-field-empty-error'));
        return false;
      }

      if (this.currentBody.length === 0) {
        alert(this.$t('labels.body-field-empty-error'));
        return false;
      }

      if (this.signature === '') {
        alert(this.$t('labels.signature-field-empty-error'));
        return false;
      }

      BojAPI.sendEmailPreview(this.subject, this.currentBody, this.signature)
          .then((response) => {
            this.$notify({
              group: 'foo',
              text: 'Förhandsvisning skickat till ' + response.data,
              type: 'success',
              duration: 3000,
            });
          })
          .catch((error) => {
          });
    },


    /**
     * Retrieve an estimate for number
     * of recipients from VUE_APP_API_ELASTIC.
     */
    sendMailEstimate() {
      if (this.currentSubject.length === 0) {
        this.showError(this.$t('labels.subject-field-empty-error'))
        return false;
      }

      if (this.currentBody.length === 0) {
        this.showError(this.$t('labels.body-field-empty-error'))
        return false;
      }

      if (this.signature === '') {
        this.showError(this.$t('labels.signature-field-empty-error'))
        return false;
      }

      BojAPI.sendEmailWithListsPreview(this.subject, this.currentBody, this.signature, this.recipients, this.date, this.time)
          .then((response) => {
            this.prepareSending = response.data;
          })
          .catch((error) => {
            this.showError(error.response.data)
          });
    },

    /**
     * Send mail
     *
     * Validates input then submit values for
     * backend processing.
     */
    sendMail() {
      if (this.currentSubject.length === 0) {
        this.showError(this.$t('labels.subject-field-empty-error'))
        return false;
      }

      if (this.currentBody.length === 0) {
        this.showError(this.$t('labels.body-field-empty-error'))
        return false;
      }

      if (this.signature === '') {
        this.showError(this.$t('labels.signature-field-empty-error'))
        return false;
      }

      // Remove entered dates if delayed sending is not checked.
      if (!this.delayedSending) {
        this.date = '';
        this.time = '';
      }

      let formData = this.formData;
      let self = this;

      Object.keys(this.fileRecords).forEach(function (key) {
        let file = self.fileRecords[key].file;
        formData.append('files[' + key + ']', file);
      });


      formData.append('subject', this.currentSubject);
      formData.append('body', this.currentBody);
      formData.append('signature_id', this.signature);
      formData.append('lists', this.recipients);
      formData.append('date', this.date);
      formData.append('time', this.time);


      BojAPI.sendEmailWithLists(formData)
          .then((response) => {
            this.showConfirmation(this.$t('labels.mailing-queued'))
            this.$emit('refreshList');
            self.closeSendMail();
          })
          .catch((error) => {
            this.showError(this.$t('labels.unknown-error'))
          });
    },
  },
};
</script>
<style scoped> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */

</style>
