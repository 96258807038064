<template>
  <layout-main>
    <div class="home">
      <send-mail-extended
          @refreshList="refreshList"
          :subject="newMailSubject"
          :body="newMailBody"
          @closed="composeMail = null"
          v-if="composeMail"
      ></send-mail-extended>
      <view-mail v-if="viewMail" @refreshList="refreshList" @reuseMail="openReuseableMail" @closed="viewMail = null"
                 :email="viewMail"></view-mail>
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.email') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/email" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/email" class="breadcrumbs-link"> {{ $t('labels.email') }}</router-link>
            </div>
          </div>
          <div class="subheader-right">
            <button @click="composeMail = true" class="btn btn-sm btn-light mr-3"><i class="fas fa-envelope"></i> {{ $t('labels.email-new') }}
            </button>

            <button @click="updateData()" class="btn btn-sm btn-light mr-3"><i class="fas fa-sync"></i> {{ $t('labels.update-the-list') }}
            </button>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
          <div class="widget progress-list">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.email') }} <small>{{ lists.length }} {{ $t('labels.made').toLowerCase() }}</small>
                </h3>
              </div>
            </div>
            <div class="widget-body no-padding">
              <vuetable
                  class="clickable-vuetable"
                  ref="vuetable"
                  @vuetable:row-clicked="onRowClicked"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  no-data-template="Ingen data tillgänglig"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
              >
              </vuetable>
              <div style="padding-top: 10px;">
                <vuetable-pagination ref="pagination"
                                     @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import LayoutMain from '@/views/Layouts/Main';
import ViewMail from '@/components/ViewMail';
import SendMailExtended from '@/components/forms/SendMailExtended';
import BojAPI from '@/api/boj';
import _ from 'lodash';

export default {
  name: 'home',
  components: {
    LayoutMain,
    Vuetable,
    ViewMail,
    SendMailExtended,
    VuetablePagination,
  },
  data() {
    return {
      lists: {},
      newMailSubject: '',
      newMailBody: '',
      isLoading: false,
      viewMail: null,
      composeMail: null,
      fields: [
        {
          width: '16%',
          name: 'subject',
          title: this.$t('labels.subject'),
          sortField: 'subject',
        },
        {
          width: '10%',
          name: 'log',
          title: this.$t('labels.receiver'),
          sortField: 'log',
          callback: this.gender,
        },
        {
          width: '12%',
          name: 'email_signature.name',
          title: this.$t('labels.user'),
          sortField: 'email_signature.name',
        },
        {
          width: '12%',
          name: 'sent_at',
          title: this.$t('labels.sent'),
          sortField: 'sent_at',
          callback: this.sentAt,
        },
        {
          width: '12%',
          name: 'user.name',
          title: this.$t('labels.sent-by'),
          sortField: 'user.name',
        },
      ],
      perPage: 20,
    };
  },

  watch: {
    lists(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getEmails()
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    BojAPI.getEmails().then((response) => {
      this.setData(response.data);
      next();
    });
  },

  methods: {
    gender: function (value) {
      return value.length;
    },

    updateData() {
      this.$root.loading = true;

      this.refreshList().then(() => {
        this.$refs.vuetable.refresh();
        this.$root.loading = false;
      });
    },

    refreshList() {
      return BojAPI.getEmails().then((response) => {
        this.setData(response.data);
      });
    },
    sentAt: function (value, field) {
      if (value === null) {
        return this.$t('labels.planned');
      }

      return value;
    },

    setData(payload) {
      this.lists = payload;
      this.isLoading = false;
    },

    openReuseableMail(subject, body) {
      this.newMailBody = body;
      this.newMailSubject = subject;
      this.composeMail = true;
    },

    onRowClicked(data, field, event) {
      this.viewMail = data;
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      let local = this.lists;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.perPage);

      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log('slot actions: on-click', data.name);
    },
  },
};
</script>
