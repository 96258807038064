<template>
  <modal name="send-message-modal" @closed="closeSendMail" :scrollable="true" :width="1000" :height="670"
         :maxHeight="600">
    <div class="clearfix">
      <h4 class="float-left">{{ email.subject }}</h4>
      <button type="submit" class="btn btn-danger float-right mt-3 mr-3" v-if="email.sent_at === null"
              @click="deleteMail">{{ $t('labels.cancel-mailing') }}
      </button>
      <button type="submit" class="btn btn-success float-right mt-3 mr-3" @click="doReuseMail">{{ $t('labels.copy') }}</button>
    </div>
    <div class="container bt-1 h-100">
      <div class="row bb-1 pt-3 pb-3">
        <div class="col-3">
          <h6 class="sublabel">{{ $t('labels.sent') }}</h6>
          {{ email.sent_at === null ? this.$t('labels.planned') : email.sent_at }}
        </div>
        <div class="col-3">
          <h6 class="sublabel">{{ $t('labels.scheduled-mailing') }}</h6>
          {{ email.send_at }}
        </div>
        <div class="col-3">
          <h6 class="sublabel">{{ $t('labels.signature') }}</h6>
          {{ email.email_signature.name }}
        </div>
        <div class="col-3">
          <h6 class="sublabel">{{ $t('labels.sent-by') }}</h6>
          {{ email.user.name }}
        </div>
      </div>
      <div class="row h-100">
        <div class="col-8 pt-3">
          <div class="form-group" v-if="email.attachments.length !== 0">
            <h6 class="sublabel">{{ $t('labels.attached-files') }}</h6>
            <div class="row" v-for="attachment in email.attachments" :key="'attachment' + attachment.id">

              <div class="col-7">
                <a :href="endpoint + 'storage/attachments/' + attachment.name"
                   target="_blank">{{ attachment.real_name }}</a>
              </div>
              <div class="col text-muted">
                {{ attachment.size / 1000 }} kb
              </div>
            </div>
          </div>
          <div class="form-group">
            <h6 class="sublabel">{{ $t('labels.content') }}</h6>
            <div v-html="email.body"></div>
          </div>
        </div>
        <div class="col-4 pt-3 bl-1 h-100">
          <h6 class="sublabel">{{ $t('labels.list') }}</h6>
          <div class="form-group">
            <div class="mb-1">
              {{ email.lists }}
            </div>
          </div>

          <h6 class="sublabel">{{ email.log.length }} {{ $t('labels.receivers') }}</h6>
          <div class="form-group">
            <div class="mb-1" v-for="log in email.log" :key="log.id">
              {{ log.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

export default {


  props: {
    email: Object,
  },
  data() {
    return {
      endpoint: process.env.VUE_APP_API_LINK,
      body: '',
      subject: '',
      getSortByOrder: true,
      getSortBy: '_score',

    };
  },
  mounted() {
    this.$modal.show('send-message-modal');
  },
  methods: {
    closeSendMail() {
      this.$emit('closed');
    },

    doReuseMail() {
      this.$modal.hide('send-message-modal');
      this.$emit('reuseMail', this.email.subject, this.email.body);
      this.$emit('closed');
    },

    deleteMail() {
      this.$confirm({
        message: `Vill du avbryta utskick och radera denna e-post?`,
        button: {
          no: 'Nej',
          yes: 'Ja',
        },
        callback: (confirm) => {
          if (confirm) {
            BojAPI.deleteEmail(this.email.id)
                .then((response) => {
                  this.$notify({
                    group: 'foo',
                    text: 'E-posten är raderat.',
                    type: 'success',
                    duration: 3000,
                  });

                  this.$emit('closed');
                  this.$emit('refreshList');
                })
                .catch((error) => {
                });
          }
        },
      });
    },
  },
};
</script>
